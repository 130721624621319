import React from "react";
import theme from "theme";
import { Theme, Text, Image, Link, LinkBox, Box, Section, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"portfolio/ukit"} />
		<Helmet>
			<title>
				Кейс uKit | Дизайнер Артём Жигалин
			</title>
			<meta name={"description"} content={"uKit — это онлайн конструктор сайтов для бизнеса, которые можно делать своими руками."} />
			<meta property={"og:title"} content={"Кейс uKit | Дизайнер Артём Жигалин"} />
			<meta property={"og:description"} content={"uKit — это онлайн конструктор сайтов для бизнеса, которые можно делать своими руками."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-512.png?v=2022-06-20T14:58:50.557Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-32.png?v=2022-06-18T12:04:38.725Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-270.png?v=2022-06-18T12:05:00.760Z"} />
		</Helmet>
		<Box
			min-width="100px"
			border-width="22px"
			border-style="solid"
			border-color="--color-lightD2"
			display="flex"
			flex-direction="column"
			padding="12px 24px 24px 24px"
			md-border-width="0px"
		>
			<Components.Header>
				<Override slot="link" />
				<Override slot="link1" />
				<Override slot="link2" />
			</Components.Header>
			<Section quarkly-title="Cover" margin="50px 0 0px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="center"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					grid-column="2/3"
					display="flex"
					flex-direction="row"
					flex-wrap="wrap"
				>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--headline2"
						width="100%"
						sm-font="--headline3"
					>
						юКит
					</Text>
					<Text
						margin="0px 0px 24px 0px"
						color="--darkL2"
						font="--lead28"
						max-width="760px"
						sm-font="--lead21"
						width="100%"
					>
						юКит — это онлайн конструктор сайтов для бизнеса, которые можно делать своими руками.
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						width="66%"
						padding="0px 16px 0px 0px"
						sm-width="100%"
						sm-min-height="auto"
						sm-height="auto"
						sm-margin="0px 0px 18px 0px"
					>
						<LinkBox align-items="flex-start" flex-direction="row" justify-content="flex-start" margin="0px 0px 6px 0px">
							<Image
								src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/uKit-favicon.png?v=2022-06-07T08:26:23.490Z"
								display="block"
								width="24px"
								height="24px"
								margin="0px 6px 0px 0px"
							/>
							<Link
								href="https://ukit.com/"
								color="--primary"
								display="inline-block"
								target="_blank"
								font="--base"
								text-decoration-line="initial"
								hover-color="#0b85db"
							>
								ukit.com
							</Link>
						</LinkBox>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Дата окончания работ: 1 июля 2020
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Сделано за 120 дней
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="33%"
						sm-width="100%"
						sm-height="auto"
						sm-min-height="auto"
					>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							UX / UI дизайн
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
							Дизайн-менеджмент
						</Text>
					</Box>
				</Box>
			</Section>
			<Section quarkly-title="YoutubeVideo" margin="0 0 12px 0" inner-width="100%">
				<Components.QuarklycommunityKitYouTube url="https://youtu.be/jII90hkzKzw" width="100%" />
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Спроектировать и оформить интерфейс конструктора сайтов. Сделать его современным и удобным для пользователей без навыков веб-дизайна.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" sm-margin="0px 24px 16px 0px">
					МОЯ РОЛЬ
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					В составе команды, я принимал участие в формировании концепции продукта и его ключевой ценности — конструктора.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Как дизайнер я спроектировал и оформил несколько ключевых разделов и фич продукта: выбор сайта в дашборде, визард, профиль пользователя, уведомления, статистика посещений сайта, оплату и прикрепление домена.
				</Text>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Краткое описание{" "}
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					юКит — это SaaS* конструктор сайтов для малого и среднего бизнеса и некоммерческих организаций.{" "}
				</Text>
				<Text margin="16px 0px 0px 0px" font="--base" color="--grey" grid-column="3/4">
					SaaS (Software as a service) —{" "}
					<br />
					это когда доступ к программам предоставляется удаленно и по подписке.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Создание начинается с выбора шаблона, который может существенно сэкономить время.
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube url="https://youtu.be/rtFnYFhFJ3g" width="100%" />
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Пользователю юКит не обязательно обладать навыками веб-дизайнера, он может сделать себе сайт пользуясь готовыми шаблонами, виджетами и блоками.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					После выбора шаблона пользователь может добавить отредактировать или убрать ненужные виджеты. В конструкторе он так же может создавать страницы и просматривать результат.
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube url="https://youtu.be/XecSgKu-7x4" width="100%" />
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					После публикации сайта, пользователь может настроить статистику, прикрепить свой домен, проверить готовность к продвижению и другие действия необходимые для того чтобы сделать работу сайта эффективной.{" "}
				</Text>
			</Section>
			<Section quarkly-title="Video" margin="0 0 12px 0" inner-width="100%" sm-padding="0px 0 0px 0">
				<Components.QuarklycommunityKitYouTube url="https://youtu.be/Z02S2HLB_bk" width="100%" />
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Выбор сайта
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Спроектировать интерфейс выбора активного сайта для пользователя. Необходимо было учесть ситуации когда у пользователя мало и когда много сайтов.{" "}
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Я расположил кнопку открытия окна в хэдере, чтобы выбор сайта был доступен в любом разделе дашборда.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Выбор должен наглядно показывать карточки сайтов. Чтобы пользователю было удобно ориентироваться кроме названия карточка должна содержать скриншот главной страницы и URL-адрес сайта.{" "}
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Дополнительно на карточке я расположил часто используемые действия.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Я оформил различные состояния карточки сайта:
				</Text>
				<List
					margin="6px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					font="--lead21"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					color="--darkL2"
				>
					<Text margin="0px 0px 6px 0px">
						выбран,
					</Text>
					<Text margin="0px 0px 6px 0px">
						оплачен,
					</Text>
					<Text margin="0px 0px 6px 0px">
						закончился триал,
					</Text>
					<Text margin="0px 0px 6px 0px">
						не опубликован,
					</Text>
					<Text margin="0px 0px 6px 0px">
						заблокирован,
					</Text>
				</List>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					и прочие редкие случаи.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Исходя из пользовательских ожиданий добавил фильтрацию и сортировку.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Для ситуации когда сайтов у пользователя много добавил отображение в виде списка.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-projects-ru-1.png?v=2022-06-18T12:44:14.264Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Выбранный сайт подсвечивается и показывает свой статус
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-projects-list-ru-2.png?v=2022-06-18T12:44:27.300Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Если у пользователя много сайтов, то для удобного просмотра, он может включить режим списка
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-projects-on-hover-ru-3.png?v=2022-06-18T12:44:46.888Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						При наведении курсора на карточку отображаются релевантные действия с сайтом
					</Override>
					<Override slot="Image" border-width="1px" border-style="solid" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Раздел статистики
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Пользователям нужен просто подключать и наглядно смотреть статистику посещений сайта.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Статистика должна забирать и показывать данные из Яндекс.Метрика и Google Analytics.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Я выделил наиболее интересную пользователям информацию. Далее я оформил эту информацию в виде наглядных графиков, диаграмм, таблиц и фактоидов.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Так же я спроектировал пошаговый визард подключения статистики с вспомогательными инструкциями.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-statistics-visitors-ru-1.png?v=2022-06-18T13:00:19.530Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Основное, что хочет видеть владелец сайта, это наглядную динамику посещений
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-statistics-audience-ru-2.png?v=2022-06-18T13:00:19.528Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Характеристики аудитории показаны в виде круговых диаграмм
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-statistics-sources-ru-3.png?v=2022-06-18T13:00:19.531Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Следующее по важности это то, откуда приходили посетители, по какой поисковой фразе, из какого города
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-statistics-content-ru-4.png?v=2022-06-18T13:00:19.535Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Последними по важности идут характеристики контента и возможность перейти к детальному просмотру
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Покупка и подключение доменов
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Спроектировать интерфейс покупки и прикрепления доменного имени к сайту.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Решение должно быть в виде понятного новичку пошагового визарда.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					На стартовом экране показывается два варианта действий: покупка или прикрепление домена.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					В случае покупки, пользователь вводит нужное имя домена и далее происходит процесс поиска с выводом результатов.
В этом списке пользователи видят какие домены свободны, а какие нет, а так же стоимость за которую они могут купить соответствующий домен.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					На странице прикрепления пользователь в три шага прикрепляет домен к своему сайту.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Так же я описал инструкции по смене NS записей для каждого из популярных регистраторов.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-default-en-1.png?v=2022-06-19T09:48:28.523Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Выбор между покупкой и привязкой домена
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-search-en-2.png?v=2022-06-19T09:48:37.774Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Поиск нужного домена. В результатах сначала показываются самые популярные зоны
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-connect-step1-en-3.png?v=2022-06-19T09:48:49.086Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Процесс привязки домена к сайту
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-connect-step2-en-4.png?v=2022-06-19T09:50:07.476Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Второй этап привязки, указание NS
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-connect-step3-en-5.png?v=2022-06-19T09:50:36.123Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Финальный шаг
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-domains-connect-instructions-en-6.png?v=2022-06-19T09:50:44.215Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Инструкция как указывать NS у различных регистраторов доменов
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Оплата и управление платежами
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Спроектировать интерфейс оплаты, страницу управления платежами и историю платежей
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					На экране оплаты я разместил выбор периода и способ оплаты. Чаще всего оплата происходит с помощью банковской карты, поэтому я поставил её по умолчанию, а форму для ввода данных сделал максимально похожей на банковскую карту.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					На странице управления платежами я списом разместил все активные и неактивные подписки.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
				>
					Историю оплат я оформил в виде таблицы где располагаются все факты оплаты.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-payment-default-en-1.png?v=2022-06-19T09:36:13.540Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Важно показывать на одном экране выбор периода и форму оплаты, чтобы сократить количество шагов и увеличить конверсию
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-payment-manage-en-2.png?v=2022-06-19T09:36:24.259Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Экран управления подписками
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-payment-history-en-3.png?v=2022-06-19T09:36:32.884Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						История инвоисов
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-payment-details-en-4.png?v=2022-06-19T09:36:39.715Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Детали инвоиса
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Система уведомлений
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4" />
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Нужен способ уведомления пользователей об ошибках, окончании пробного или оплаченного периода, акциях, новостях и прочих событиях.
				</Text>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Нужна форма отправки персональных и общих уведомлений.
				</Text>
			</Section>
			<Section quarkly-title="Text+SideHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Components.BaseUppercase grid-column="1/2" lg-grid-column="1/4">
					РЕШЕНИЕ
				</Components.BaseUppercase>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead21"
					max-width="760px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					На экране оплаты я разместил выбор периода и способ оплаты. Чаще всего оплата происходит с помощью банковской карты, поэтому я поставил её по умолчанию, а форму для ввода данных сделал максимально похожей на банковскую карту.
				</Text>
			</Section>
			<Section quarkly-title="Images" inner-width="100%">
				<Override
					slot="SectionContent"
					max-width="1920px"
					width="100%"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					md-grid-template-columns="1fr"
					justify-items="center"
				/>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-notification-default-en-1.png?v=2022-06-18T13:02:09.498Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Кнопка вызова списка уведомлений находится в хэдере дашборда
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-notification-toast-en-2.png?v=2022-06-18T13:02:17.066Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Вид тоста с важной для пользователя информацией
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-notification-form-preview-en-4.png?v=2022-06-19T15:34:38.086Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Форма отправки уведомлений пользователям содержит превью
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="2/3"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-notification-form-post-en-5.png?v=2022-06-19T15:34:47.702Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Перед отправкой можно тонко настроить дату отправки и удаления
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
				<Components.ImageViewer
					grid-column="1/2"
					md-grid-column="auto"
					padding="0px 0px 20px 0px"
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-notification-all-types-en-3.png?v=2022-06-18T13:02:26.062Z"
					md-width="100%"
					md-max-width="none"
					width="100%"
					max-width="none"
				>
					<Override slot="Figure" margin="0px 0px 0px 0px" />
					<Override slot="Sign" display="none" />
					<Override slot="Text" font="--base" color="--darkL2">
						Варианты уведомлений и тостов
					</Override>
					<Override slot="Image" border-style="solid" border-width="1px" border-color="--color-lightD2" />
					<Override slot="Authorship" display="none" />
				</Components.ImageViewer>
			</Section>
			<Section quarkly-title="SubHeader" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--headline3"
				>
					Результаты
				</Text>
			</Section>
			<Section quarkly-title="Text" margin="0px 0 0px 0" padding="12px 0 12px 0" inner-width="100%">
				<Override
					slot="SectionContent"
					align-items="start"
					display="grid"
					grid-template-columns="1fr 640px 1fr"
					md-grid-template-columns="1fr"
				/>
				<Text
					margin="16px 0px 0px 0px"
					color="--darkL2"
					font="--lead28"
					max-width="720px"
					grid-column="2/3"
					lg-grid-column="1/4"
					sm-font="--lead21"
					sm-margin="0px 0px 0px 0px"
				>
					Такие опросы — хороший способ собрать базу потенциальных клиентов, узнать их мнение или собрать данные о сотрудниках.
				</Text>
			</Section>
			<Components.PrevNextButtons>
				<Override slot="text">
					Мини-проекты
				</Override>
				<Override slot="linkBox" href="/portfolio/mini-projects" />
				<Override slot="text1">
					юКит Альт
				</Override>
				<Override slot="linkBox1" href="/portfolio/ukitalt" />
			</Components.PrevNextButtons>
			<Components.Footer />
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"622f01726ba4ec00186af2fd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script type={"text/javascript"} place={"endOfHead"} rawKey={"62b188e99e03ae0e7196a484"}>
				{"   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(52528996, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true\n   });"}
			</script>
			<script async={false} src={"https://www.googletagmanager.com/gtag/js?id=G-5FMRH52STD"} place={"endOfHead"} rawKey={"62d57fa6534ed0228e31f79a"} />
			<script place={"endOfHead"} rawKey={"62d57fb994ee59fbdc7b583c"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-5FMRH52STD');"}
			</script>
		</RawHtml>
	</Theme>;
});